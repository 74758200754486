import { useSubCore } from "@/hooks/useSubCore";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import router from "next/router";
import { memo, useContext, useState } from "react";
import { GrTableAdd } from "react-icons/gr";
import { IoHomeSharp } from "react-icons/io5";
import { Ri24HoursLine } from "react-icons/ri";
import { AccountModal } from "../AccountModal";
import { BetSlip } from "../BetSlipe";
import { NavigationSports } from "../NavigationSports";
import { AuthContext } from "../context/AuthContext";
import redirectTo from "../utils/redirectTo";
import styles from "./styles.module.scss";

const FooterMobileTwo = ({ sportLevels, liveLevels, mgmLevels }) => {
	// const [openModalBetslip, setOpenModalBetslip] = useState(false)
	const [openModalPopularEvents, setOpenModalPopularEvents] = useState(false);
	const [openModalAccount, setOpenModalAccount] = useState(false);
	const { t } = useTranslation();
	const { setOpenLoginModal } = useSubCore();

	const { user } = useContext(AuthContext);
	const { openModalBetslip, setOpenModalBetslip } = useSubCore();

	return (
		<>
			<ul className={`${styles.footerMenu} ${styles.dLgNone}`}>
				<li>
					<a
						// biome-ignore lint/a11y/useValidAnchor: <explanation>
						onClick={() =>
							redirectTo(
								"/deposit/?tabIndex=0",
								user,
								router,
								setOpenLoginModal,
							)
						}
						className={`${styles.dGrid} ${styles.justifyContentCenter}`}
					>
						<span>
							<img src="/assets/img/deposito.png" alt="" />
						</span>
						<span className={styles.texta}>{t("Depósito")}</span>
					</a>
				</li>

				<li>
					<a
						onClick={() =>
							redirectTo(
								"/deposit/?tabIndex=1",
								user,
								router,
								setOpenLoginModal,
							)
						}
						className={`${styles.dGrid} ${styles.justifyContentCenter}`}
					>
						<span>
							<img src="/assets/img/payout.png" alt="" />
						</span>
						<span className={styles.texta}>{t("Saque")}</span>
					</a>
				</li>

				<li className={`${styles.headerBartwo} ${styles.dLgNone}`}>
					<Link
						href={"/"}
						className={`${styles.dGrid} ${styles.middleButton} ${styles.justifyContentCenter}`}
					>
						<span>
							<IoHomeSharp />
						</span>
						<span className={styles.texta}>{t("Home")}</span>
					</Link>
				</li>

				<li>
					<a
						// biome-ignore lint/a11y/useValidAnchor: <explanation>
						onClick={() =>
							redirectTo("/event", user, router, setOpenLoginModal)
						}
						className={`${styles.dGrid} ${styles.justifyContentCenter}`}
					>
						<span>
							<GrTableAdd />
						</span>
						<span className={styles.texta}>{t("Evento")}</span>
					</a>
				</li>

				<li>
					<a
						onClick={() =>
							redirectTo(
								"/deposit/?tabIndex=3",
								user,
								router,
								setOpenLoginModal,
							)
						}
						className={`${styles.dGrid} ${styles.justifyContentCenter}`}
					>
						<span>
							<Ri24HoursLine />
						</span>
						<span className={styles.texta}>{t("Perguntas")}</span>
					</a>
				</li>
			</ul>

			{openModalPopularEvents && (
				<NavigationSports
					closePopularEvents={setOpenModalPopularEvents}
					openModalPopularEvents={openModalPopularEvents}
				/>
			)}

			{openModalBetslip && (
				<BetSlip
					mgmLevels={mgmLevels}
					liveLevels={liveLevels}
					sportLevels={sportLevels}
					close={setOpenModalBetslip}
					openModalBetslip={openModalBetslip}
				/>
			)}

			{openModalAccount && (
				<AccountModal
					close={setOpenModalAccount}
					openModalACcount={openModalAccount}
				/>
			)}
		</>
	);
};

export default memo(FooterMobileTwo);
