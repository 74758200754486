import { useEffect, useState } from "react";

export default function useVersion() {
	const [version, setVersion] = useState("");
	useEffect(() => {
		// Carrega o número da versão do arquivo version.txt
		fetch("/version.txt")
			.then((response) => response.text())
			.then((data) => setVersion(data))
			.catch((error) => console.error("Erro ao carregar a versão:", error));
	}, []);

	return {
		version,
	};
}
