import { ImageSetBet } from "@/bet-nextv1/ImageSetBet";
import redirectTo from "@/bet-nextv1/utils/redirectTo";
import { useCore } from "@/core-nextv3/core/core";
import { useSubCore } from "@/hooks/useSubCore";
import { fromUnixTime } from "date-fns";
import { format, toZonedTime } from "date-fns-tz";
import { useRouter } from "next/router";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import styles from "./styles.module.scss";

export const HighlightGame: React.FC<any> = ({ data }) => {
	const { setAwaitLoadingHTTP, selectedLanguage } = useCore();

	let date: string;

	if (typeof data.startDate === "object") {
		const zonedDateTime: any = toZonedTime(
			fromUnixTime(data.startDate._seconds),
			"Asia/Seoul",
		);
		date = format(zonedDateTime, "HH:mm");
	} else {
		const zonedDateTime: any = toZonedTime(data.startDate, "Asia/Seoul");
		date = format(zonedDateTime, "HH:mm");
	}

	const router = useRouter();

	const { user } = useContext(AuthContext);

	const {
		setSelectedLeague,
		setSelectedLocation,
		setSelectedSport,
		setReloadGames,
		setOpenLoginModal,
		setVirtual,
		setEndDate,
		setSelectedId,
	} = useSubCore();

	const handleSelectGame = async () => {
		setAwaitLoadingHTTP(true);

		setSelectedSport(data.sport.id);
		setVirtual(undefined);

		setSelectedLeague(data.league);
		setSelectedId(data.id);

		setEndDate(null);
		setSelectedLocation(null);

		setReloadGames(true);

		if (router?.asPath !== "/sports/") {
			redirectTo("/sports", user, router, setOpenLoginModal);
		}

		setAwaitLoadingHTTP(false);
	};

	return (
		<div
			className={styles.content}
			onClick={() => {
				handleSelectGame();
			}}
		>
			<div className={styles.sportIcon}>
				<ImageSetBet src={data?.sport?.icon?._url} />
			</div>

			<div className={styles.participantsName}>
				<div className={styles.date}>{date}</div>
				{data.participants?.map((participant: any) => (
					<div key={participant.name} className={styles.participantContainer}>
						<ImageSetBet
							src={participant?.flag?._url}
							placeholder="/assets/img/flags/default.png"
						/>
						<span>
							{selectedLanguage === "pt"
								? participant.name_br
								: participant.name_ko}
						</span>
					</div>
				))}
			</div>
		</div>
	);
};
