import redirectTo from "@/bet-nextv1/utils/redirectTo";
import { useCore } from "@/core-nextv3/core/core";
import { useResponsive } from "@/core-nextv3/util/useResponsive";
import { useSubCore } from "@/hooks/useSubCore";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { useContext, useState } from "react";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { AuthContext } from "../../context/AuthContext";
import styles from "./styles.module.scss";

export const AccordionSports = ({ children, title, image, handler }: any) => {
	const [toggle, setToggle] = useState(true);
	const { isDesktop } = useResponsive();
	const {
		setSelectedLeague,
		setSelectedSport,
		setReloadGames,
		setVirtual,
		setOpenLoginModal,
	} = useSubCore();
	const { setAwaitLoadingHTTP } = useCore();

	const router = useRouter();
	const { user } = useContext(AuthContext);

	const handleToggle = () => {
		if (isDesktop) {
			if (toggle) {
				setSelectedSport("6046");
				setVirtual(false);
				setSelectedLeague(null);
				setToggle(!toggle);

				if (router?.asPath !== "/sports/") {
					setReloadGames(true);
					setSelectedSport(null);
					redirectTo("/sports", user, router, setOpenLoginModal);
				} else {
					setSelectedSport(null);
					setReloadGames(true);
				}
			} else {
				setToggle(!toggle);
			}
		} else {
			setToggle(!toggle);
			setSelectedSport("6046");
			setSelectedLeague(null);
		}
	};

	return (
		<motion.section
			initial={true}
			className={styles.accordionSports}
			onClick={() => handleToggle()}
		>
			<motion.div className={styles.accordionTitle} layout>
				<div className={styles.rigth}>
					{image}
					{title}
				</div>

				{toggle ? (
					<TiArrowSortedDown className={styles.accordionSvg} />
				) : (
					<TiArrowSortedUp className={styles.accordionSvg} />
				)}
			</motion.div>
			<motion.div onClick={(e) => e.stopPropagation()}>
				{toggle ? children : ""}
			</motion.div>
			{/* <div className={styles.accordionLine}></div> */}
		</motion.section>
	);
};
