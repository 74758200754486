import redirectTo from "@/bet-nextv1/utils/redirectTo";
import { useCore } from "@/core-nextv3/core/core";
import { collectionDocument } from "@/core-nextv3/document/document.api";
import { useResponsive } from "@/core-nextv3/util/useResponsive";
import { useSubCore } from "@/hooks/useSubCore";
import { SPORT_SETTING } from "@/setting/setting";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import styles from "./styles.module.scss";

//export const AccordionSport = ({ children, title, icon, quantity }: any) => {
export const AccordionSport = ({ children, title, sport, handler }: any) => {
	const [toggle, setToggle] = useState(false);

	const { setAwaitLoadingHTTP } = useCore();
	const {
		setSelectedLeague,
		setSelectedSport,
		setReloadGames,
		setVirtual,
		sports,
		setSports,
		setOpenLoginModal,
	} = useSubCore();
	const { isDesktop } = useResponsive();

	const { user } = useContext(AuthContext);

	const router = useRouter();

	useEffect(() => {
		loadSports();
	}, []);

	const loadSports = async () => {
		const query = {
			where: [
				{
					field: "active",
					operator: "==",
					value: true,
				},
			],
		};

		const sports = await collectionDocument(SPORT_SETTING.merge(query));

		if (sports.status === true) {
			// sports.collection.sort(function(x,y){ return x.id == '6046' ? -1 : y.id == '6046' ? 1 : 0; });
			const desiredOrder = [6046, 48242, 154914, 154830, 35232];

			// Sort the array based on the desired order
			const sorted = sports.collection.sort(
				(a, b) => desiredOrder.indexOf(a.id) - desiredOrder.indexOf(b.id),
			);

			setSports(sorted);
		}
	};

	const isVirtual = sport?.id?.includes("esports") ? "E-sports" : null;
	// const handleToggle = () => {
	//     setAwaitLoadingHTTP(true);
	//     if (!toggle) {
	//         setSelectedSport(sport.id == '6046' ? sport.id : "E-Sports")
	//         setSelectedLeague(null);
	//         setToggle(!toggle);

	//         if (router?.asPath !== '/sports/') {
	//             setReloadGames(true);
	//             router.push("/sports")
	//         } else {
	//             setReloadGames(true);
	//         }
	//     }
	//     setToggle(!toggle);
	//     setAwaitLoadingHTTP(false);
	// }

	const handleToggle = () => {
		if (isDesktop) {
			if (toggle) {
				setSelectedSport(!isVirtual ? sport.id : "esports");
				setVirtual(!!isVirtual);
				setSelectedLeague(null);
				setToggle(!toggle);

				if (router?.asPath !== "/sports/") {
					setReloadGames(true);
					redirectTo("/sports", user, router, setOpenLoginModal);

					if (handler) {
						handler(false);
					}
				} else {
					setReloadGames(true);

					if (handler) {
						handler(false);
					}
				}
			} else {
				setToggle(!toggle);
			}
		} else {
			setToggle(!toggle);

			if (toggle) {
				setAwaitLoadingHTTP(true);
				setSelectedSport(!isVirtual ? sport.id : "E-sports");
				setVirtual(!!isVirtual);
				setSelectedLeague(null);

				if (router?.asPath !== "/sports/") {
					setReloadGames(true);
					redirectTo("/sports", user, router, setOpenLoginModal);
				} else {
					setReloadGames(true);
				}

				handler(false);
			}
		}
	};

	return (
		<motion.div
			initial={true}
			className={styles.accordionSport}
			onClick={() => handleToggle()}
		>
			<motion.div className={styles.accordionTitleSport} layout>
				<div className={styles.rigth}>
					{/*{sport.icon}*/}
					<img
						src={
							!isVirtual
								? sports?.find((e: any) => e.id === sport.id)?.icon._url
								: "/assets/img/virtual.png"
						}
						alt=""
					/>
					{title}
				</div>
				{/*<p className={styles.number}>{quantity}</p>*/}
				<p className={styles.number}>{sport?.count}</p>
			</motion.div>
			<motion.div onClick={(e) => e.stopPropagation()}>
				{toggle ? children : ""}
			</motion.div>
			{/* <div className={styles.accordionLine}></div> */}
		</motion.div>
	);
};
