import {
	checkoutBetCart,
	clearCart,
	delBetBonusCart,
	setItemCart,
	setMinigameItemCart,
	setTotalBetCart,
} from "@/core-nextv3/bet/bet.api";
import { useCore } from "@/core-nextv3/core/core";
import { useResponsive } from "@/core-nextv3/util/useResponsive";
import { betNameParser } from "@/hooks/betNameParser";
import { useReloadBetCartItems } from "@/hooks/useReloadBetCartItems";
import { useSubCore } from "@/hooks/useSubCore";
import useVersion from "@/hooks/useVersion";
import CartModel from "@/model/cart.model";
import { CART_BET_SETTING, ORDER_SETTING } from "@/setting/setting";
import { Spinner } from "@chakra-ui/react";
import CircularProgress from "@mui/material/CircularProgress";
import { isBefore } from "date-fns";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { SlReload } from "react-icons/sl";
import { AlertModal } from "../AlertModal";
import { HeaderForMobileMenu } from "../HeaderForMobileMenu/index,";
import { ImageSetBet } from "../ImageSetBet";
import { ModalTimeLive } from "../ModalTimeLive";
import { AuthContext } from "../context/AuthContext";
import { BetItemData } from "./BetItemData";
import { BetMinigameData } from "./BetMinigameData";
import styles from "./styles.module.scss";

export const BetSlip: React.FC<any> = ({
	close,
	openModalBetslip,
	homeBannersLiks,
	// sportLevels,
	// liveLevels,
	// mgmLevels,
	// lotusLevels,
	// boscoreLevels,
}) => {
	//console.log("homeBannersLiks", homeBannersLiks);
	// console.log("TESTE", sportLevels, liveLevels, mgmLevels)
	const router = useRouter();
	const { user, setIsLogged, logout, CART_BACKEND } =
		useContext<any>(AuthContext);
	const betRef: any = useRef(0);
	const { isDesktop } = useResponsive();
	const [betValue, setBetValue] = useState<any>(0);
	const [timer, setTimer] = useState<any>(null);
	const [submited, setSubmited] = useState(false);
	const {
		setBetCart,
		betCart,
		setAwaitLoadingHTTP,
		selectedLanguage,
		setIsLoadingCart,
	} = useCore();
	const {
		setOpenMessageModal,
		setMessage,
		setMessageModalIcon,
		setSelectedBet,
		setSelectedIndex,
		setOpenAdditionalOptions,
		setOpenModalTimeLive,
		openModalTimeLive,
		gameConfigs,
		setCartChanges,
		dividends,
	} = useSubCore();
	const [modalAlert, setModalAlert] = useState(false);
	const [modalMessage, setModalMessage] = useState("");
	const [showTimeModal, setShowTimeModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [timeoutId, _setTimeoutId] = useState<any>(null);
	const [slipeisUpdating, setSlipeisUpdating] = useState(false);
	const { version } = useVersion();
	const [_initCart, _setInitCart] = useState(false);
	const { reloadCart } = useReloadBetCartItems();

	const { t } = useTranslation();

	// solução drastica p/ forçar render
	const [_state, updateState] = useState<any>();
	const forceUpdate = useCallback(() => updateState({}), []);

	const [slipeInfo, setSplipeInfo] = useState({
		minimumBetAmount: 0,
		maximumBetAmount: 0,
		maximumWinningBetValue: 0,
		maximumDividend: 0,
	});

	const _ref: any = useRef();
	// console.log(betCart)

	// limpa o carrinho na 1ª renderizaçao
	// const renderClear = async () => {
	//   await clearCart(CART_BET_SETTING)
	//   setAwaitLoadingHTTP(false)
	//   setInitCart(true)
	// }

	// useEffect(() => {
	//   if(!initCart){
	//     setAwaitLoadingHTTP(true)
	//     renderClear()
	//   }
	// }, [])

	// useInterval(async () => {
	//   // caso esteja fazendo alguma operação no carrinho, não deve atropelar recarregando

	//   if (!isLoadingCart && betCart?.items?.length > 0) {
	//     await reloadBetCartItems(
	//       CART_BET_SETTING.merge({
	//         dividend: {
	//           referencePath: "default/levelTables/documents/7JkchxNheBXvCZddsUc2",
	//         },
	//       })
	//     );
	//   }
	//   if (reloadCart?.data?.changed && reloadCart?.error) {
	//     let message = t(reloadCart?.error);
	//     for (let i = 0; i < reloadCart.data.values.length; i++) {
	//       message = message.replace("<" + i + ">", reloadCart.data.values[i]);
	//     }
	//     setCartChanges(true);
	//     setBetCart(reloadCart?.data);
	//     setMessageModalIcon("warning");
	//     setMessage(message);
	//     setOpenMessageModal(true);
	//   } else if (reloadCart?.data?.changed == false) {
	//     setCartChanges(false);
	//   }
	// }, 10000);

	useEffect(() => {
		if (CART_BACKEND) {
			if (reloadCart?.data?.changed && reloadCart?.error) {
				let message = t(reloadCart?.error);

				for (let i = 0; i < reloadCart.data.values.length; i++) {
					message = message.replace(`<${i}>`, reloadCart.data.values[i]);
				}

				setCartChanges(true);
				setBetCart(reloadCart?.data);
				setMessageModalIcon("warning");
				setMessage(message);
				setOpenMessageModal(true);
			} else if (reloadCart?.data?.changed === false) {
				setCartChanges(false);
			}
		}
	}, [reloadCart]);

	const handleLogout = async () => {
		setAwaitLoadingHTTP(true);
		setIsLogged(false);
		// await calls(
		await logout();
		const result = await clearCart(CART_BET_SETTING);
		// await delBetBonusCart(CART_BET_SETTING)
		// )

		setBetCart(result?.data);
		setSelectedBet(null);
		setSelectedIndex(null);
		setOpenAdditionalOptions(false);
		router.push("/");
		setAwaitLoadingHTTP(false);
	};

	// format 100000 in 100,000
	function format(input: any) {
		let nStr = `${input}`;
		nStr = nStr.replace(/\,/g, "");
		const x = nStr.split(".");
		let x1 = x[0];
		const x2 = x.length > 1 ? `.${x[1]}` : "";
		const rgx = /(\d+)(\d{3})/;

		while (rgx.test(x1)) {
			x1 = x1.replace(rgx, "$1" + "," + "$2");
		}

		input = x1 + x2;
		return input;
	}

	const setInfos = (levels: any) => {
		const data: any = slipeInfo;
		const level = user?.level;

		for (let i = 0; i < levels?.length; i++) {
			if (levels[i]?.level?.value === level?.value) {
				const info = levels[i];
				const minAmount: any =
					info?.minimumValueForSingleBet ||
					info?.minimumValueForSingleBet ||
					info?.minimumBetAmount ||
					0;
				const maxAmount: any =
					info?.maximumValueForSingleBet ||
					info?.maximumValueForSingleBet ||
					info?.maximumBetAmount ||
					0;
				const maxWin: any =
					info?.maximumBetAmountWonSingle ||
					info?.maximumBetAmountWonSingle ||
					info?.maximumValueForGain ||
					0;
				const maxDividend: any =
					info?.maximumDividend || info?.maximumDividendAmount || 0;

				data.minimumBetAmount = minAmount;
				data.maximumBetAmount = maxAmount;
				data.maximumWinningBetValue = maxWin;
				data.maximumDividend = maxDividend;
			}
		}

		setSplipeInfo(data);
		forceUpdate();
	};

	useEffect(() => {
		if (user) {
			const route = router?.asPath;

			if (route?.split("/")?.length > 3) {
				const splited = route?.split("/");
				const prov = splited[2];
				const game = splited[3];

				if (prov === "lotus" && game === "baccarat") {
					setInfos(gameConfigs?.bakara);
				} else if (prov === "lotus" && game === "oddOrEven") {
					setInfos(gameConfigs?.evenOdd);
				} else if (prov === "mgm" && game === "baccarat") {
					setInfos(gameConfigs?.bakara);
				} else if (prov === "mgm" && game === "oddOrEven") {
					setInfos(gameConfigs?.evenOdd);
				} else if (prov === "boscore" && game === "ladder") {
					setInfos(gameConfigs?.ladder);
				} else if (prov === "boscore" && game === "powerball") {
					// console.warn('pasdsadsdsadsa', boscoreLevels)
					setInfos(gameConfigs?.powerBall);
				}
			} else {
				const data = slipeInfo;

				if (route === "/sports/") {
					setInfos(gameConfigs?.levels);
				} else if (route === "/liveSports/") {
					setInfos(gameConfigs?.levels);
				} else {
					data.minimumBetAmount = 0;
					data.maximumBetAmount = 0;
					data.maximumWinningBetValue = 0;
					data.maximumDividend = 0;
				}
			}
		}
	}, [user, router?.asPath, gameConfigs]);

	// console.warn('slipeInfo', slipeInfo)

	// useEffect(() => {
	//   if (betCart?.total) {
	//     setBetValue(betCart?.total)
	//     betRef.current.value = format(betCart?.total)
	//   }
	// }, [betCart])

	// limpa os hooks de valores em caso de logout de usuario
	useEffect(() => {
		if (!user) {
			setBetValue(0);
			betRef.current.value = 0;
		}
	}, [user]);

	useEffect(() => {
		const handleRouteChange = () => {
			if (betCart?.items?.length > 0) {
				clearBetCart();
			}

			if (betRef?.current?.value !== 0) {
				handleClearInput();
			}
		};

		router.events.on("routeChangeComplete", handleRouteChange);

		return () => {
			router.events.off("routeChangeComplete", handleRouteChange);
		};
	}, []);

	const handleChangeValue = (event: any) => {
		const memo = event.target.value.split(",");
		let value: any;

		if (memo.length === 1) {
			value = Number.parseInt(event.target.value);
		} else {
			let aux = "";

			for (let i = 0; i < memo?.length; i++) {
				aux += memo[i];
			}

			value = Number.parseInt(aux);
		}

		if (value > user?.sport) {
			betRef.current.value = 0;

			return;
		}

		if (Number.isNaN(value)) {
			setBetValue(0);
			betRef.current.value = 0;
		} else {
			setBetValue(value);
			betRef.current.value = Intl.NumberFormat("en", {
				maximumFractionDigits: 0,
			}).format(value);
		}

		handleTimer(value);
	};

	const handleTimer = (value: number) => {
		//Carrinho Backend
		if (CART_BACKEND) {
			if (timer) {
				clearTimeout(timer);
			}

			setTimer(
				setTimeout(async () => {
					if (value) {
						//setAwaitLoadingHTTP(true)
						// verificar if(value) gera false condition se value = 0

						const result = await setTotalBetCart(
							CART_BET_SETTING.merge({
								total: value,
								destination: { id: "sport", label: "", value: "sport" },
							}),
						);

						//setAwaitLoadingHTTP(false)
						if (result?.status) {
							setBetCart(result?.data);
							setSlipeisUpdating(false);
						} else {
							setBetValue(0);

							if (betRef?.current) {
								betRef.current.value = 0;
							}

							setMessageModalIcon("warning");
							const message = t(result.error);
							setMessage(message);
							setOpenMessageModal(true);
							setSlipeisUpdating(false);
						}

						setAwaitLoadingHTTP(false);
					}
				}, 1000),
			);
		} else {
			//Carrinho FrontEnd
			// if (timer) {
			//   clearTimeout(timer)
			// }
			// setTimer(
			//   setTimeout(async () => {
			//     if (value) {
			//       // const result = await setTotalBetCart(
			//       //   CART_BET_SETTING.merge({ total: value })
			//       // )
			//       // if (result?.status) {
			//       //   setBetCart(result?.data)
			//       // }
			//     }
			//   }, 500)
			// )
		}
	};

	const clearBetCart = async () => {
		if (CART_BACKEND) {
			//Carrinho BackEnd
			if (timeoutId) {
				clearTimeout(timeoutId);
			}

			// setIsLoadingCart(true)
			const result = await clearCart(CART_BET_SETTING);

			if (result?.status) {
				// await clearBetBonus()
			}

			// limpa os hooks do carrinho
			setBetValue(0);

			if (isDesktop) {
				betRef.current.value = 0;
			}

			// if (betRef.current) {
			// }
			handleTimer(0);
			setBetCart(null);
			// setIsLoadingCart(false)
		} else {
			setAwaitLoadingHTTP(true);
			//Carrinho FrontEnd

			setBetValue(0);

			if (isDesktop) {
				betRef.current.value = 0;
			}

			setBetCart(null);
			setAwaitLoadingHTTP(false);
		}
	};

	useEffect(() => {
		console.log();
	}, [betCart]);

	const clearBetBonus = async () => {
		if (CART_BACKEND) {
			const result = await delBetBonusCart(CART_BET_SETTING);

			if (result?.status) {
				setBetCart(result?.data);
				setSelectedBet(null);
				setSelectedIndex(null);
				setOpenAdditionalOptions(false);
			}
		} else {
			const cart = new CartModel(betCart);
			cart.delBonus();

			setBetCart(cart);
			setSelectedBet(null);
			setSelectedIndex(null);
			setOpenAdditionalOptions(false);
		}
	};

	// limpa o carrinho quando muda de pagina ou de minigame
	// useEffect(() => {
	//     if (isDesktop) {
	//         clearBetCart();
	//     }
	//     else {
	//         const route: any = router?.asPath;
	//         if (route !== '/sports/') {
	//             clearBetCart();
	//         }
	//     }
	// }, [router?.asPath, selectedMgm])

	const parseMessage = async (data: any) => {
		const itemMessage = data?.items[0];
		const lengthItems = data?.items?.length;
		let league: any;
		let participants: any;
		let typeBet: any;
		let miniGameName: any;
		let message: any;
		let provider: any;

		if (!itemMessage.minigame) {
			if (selectedLanguage === "pt") {
				league = itemMessage?.league?.name_br;
				participants = `${itemMessage?.game?.participants[0]?.name_br} VS ${itemMessage?.game?.participants[1]?.name_br}`;
			} else {
				league = itemMessage?.league?.name_ko;
				participants = `${itemMessage?.game?.participants[0]?.name_ko} VS ${itemMessage?.game?.participants[1]?.name_ko}`;
			}

			if (lengthItems === 1) {
				typeBet = t("Single");
			} else if (lengthItems === 2) {
				typeBet = t("Duplo");
			} else {
				typeBet = `${t("Multi")} ${t("e ETC")}`;
			}

			message = `
            <p>[${t("Aposta")}] ${league}</p>
            <p>${participants}</p>
            <p>${typeBet}</p>
            <p>${new Intl.NumberFormat("en").format(data?.total)} ${t(
							"Won",
						)} ${t("foi efetuada")}.</p>
        `;
		} else {
			participants = betNameParser(itemMessage.bet);
			typeBet = false;
			miniGameName = t(itemMessage?.minigame);
			provider = t(itemMessage?.providerName);

			message = `
            <p>${provider} ${miniGameName} - ${itemMessage.game.id
							.split("-")
							.slice(-1)
							.join("")} ${t("Rodada")} ${t(participants)}</p>
            <p>${new Intl.NumberFormat("en").format(data?.total)} ${t(
							"Won",
						)} ${t("foi efetuada")}.</p>`;
		}

		setMessageModalIcon("success");
		setMessage(message);
		setOpenMessageModal(true);
	};

	const getReferenceLevelTable = () => {
		const path = router?.asPath;
		// console.warn('path', path)
		const splited = path?.split("/");
		// console.warn(splited)

		if (path === "/sports/") {
			return gameConfigs?.referencePath;
		}
		if (path === "/liveSports/") {
			return gameConfigs?.referencePath;
		}
		if (splited[2] === "mgm") {
			return gameConfigs?.referencePath;
		}
		if (splited[2] === "lotus") {
			return gameConfigs?.referencePath;
		}
		if (splited[2] === "boscore") {
			return gameConfigs?.referencePath;
		}
	};

	const dispathMessageError = (message: any) => {
		setModalAlert(false);
		setAwaitLoadingHTTP(false);
		setMessageModalIcon("warning");
		setMessage(message);
		setOpenMessageModal(true);
		setIsLoading(false);
		return;
	};

	const checkLevels = (levels: any) => {
		console.warn("cart to check", betCart, betValue, betRef.current.value);
		const level = user?.level;

		for (let i = 0; i < levels?.length; i++) {
			if (level?.value === levels[i]?.level?.value) {
				const info = levels[i];
				const minAmount: any =
					info?.minimumValueForSingleBet ||
					info?.minimumValueForSingleBet ||
					info?.minimumBetAmount ||
					0;
				const maxAmount: any =
					info?.maximumValueForSingleBet ||
					info?.maximumValueForSingleBet ||
					info?.maximumBetAmount ||
					0;
				const maxWin: any =
					info?.maximumBetAmountWonSingle ||
					info?.maximumBetAmountWonSingle ||
					info?.maximumValueForGain ||
					0;
				const maxDividend: any =
					info?.maximumDividend || info?.maximumDividendAmount || 0;

				// console.log(betCart?.totalItems, betCart?.totalRevenue, betCart?.total)
				// console.log(minAmount, maxAmount, maxWin, maxDividend)

				if (betCart?.totalItems > maxDividend) {
					console.warn("maxDividend");
					const message = `${t("Valor de dividendo maior que o permitido")} (${format(maxDividend)}X)`;
					dispathMessageError(message);
					return false;
				}

				if (betCart?.totalRevenue > maxWin) {
					console.warn("maxRevenue");
					const message = `${t("Valor de ganho da aposta não pode ser maior que")} (${format(maxWin)} ${t("Won")})`;
					dispathMessageError(message);
					return false;
				}

				if (betCart?.total < minAmount) {
					// console.log(betCart?.total, minAmount)
					console.warn("minAmount");
					const message = `${t("Valor da aposta deve ser maior que")} (${format(minAmount)} ${t("Won")})`;
					dispathMessageError(message);
					return false;
				}

				console.warn(betCart?.total);

				if (betCart?.total > maxAmount) {
					// console.log(betCart?.total, maxAmount)
					console.warn("maxAmount");
					const message = `${t("Valor da aposta deve ser menor que")} (${format(maxAmount)} ${t("Won")})`;
					dispathMessageError(message);
					return false;
				}
			}
		}

		return true;
	};

	// faz a trava no front se houver jogo ja iniciado no carrinho e sem cadiado
	const checkGamesTime = () => {
		// console.warn(betCart?.items)
		const items = betCart?.items;
		const nowDate = new Date();

		// console.warn(isBefore(nowDate, new Date(items[0]?.game?.startDate)))
		for (let i = 0; i < items?.length; i++) {
			if (!isBefore(nowDate, new Date(items[i]?.game?.startDate))) {
				const message = t("Há partidas inválidas no carrinho!");
				dispathMessageError(message);
				return false;
			}
		}

		return true;
	};

	const checkUserPermissions = () => {
		const parsinCasino = user?.allowParsinCasino;
		const route = router.asPath;
		const splitted = route.split("/");
		let limited: any = true;

		if (splitted[2] === "lotus" && splitted[3] === "baccarat") {
			limited = parsinCasino?.lotus_pXCNVbMkzkKi6a7p7VLS || true;
		} else if (splitted[2] === "lotus" && splitted[3] === "oddOrEven") {
			limited = parsinCasino?.lotus_d8fjelAHR6cyT6hZhRil || true;
		} else if (splitted[2] === "mgm" && splitted[3] === "baccarat") {
			limited = parsinCasino?.mgm_pXCNVbMkzkKi6a7p7VLS || true;
		} else if (splitted[2] === "mgm" && splitted[3] === "oddOrEven") {
			limited = parsinCasino?.mgm_d8fjelAHR6cyT6hZhRil || true;
		}

		if (limited) {
			return true;
		}

		setMessageModalIcon("warning");
		const message = t("Apostas bloqueadas para esta partida");
		setMessage(message);
		setOpenMessageModal(true);
		return false;
	};

	const checkConditions = () => {
		if (betValue > 0) {
			const path = router.asPath;
			console.warn(path, path.indexOf("baccarat"));

			if ((path === "/sports/" || path === "/sportsV2/") && checkGamesTime()) {
				return checkLevels(gameConfigs?.levels);
			}
			if (path === "/liveSports/") {
				return checkLevels(gameConfigs?.levels);
			}
			if (path.indexOf("baccarat") >= 0 && checkUserPermissions()) {
				return checkLevels(gameConfigs?.bakara);
			}
			if (path.indexOf("oddOrEven") >= 0 && checkUserPermissions()) {
				return checkLevels(gameConfigs?.evenOdd);
			}
			if (path.indexOf("ladder") >= 0) {
				return checkLevels(gameConfigs?.ladder);
			}
			if (path.indexOf("powerball") >= 0) {
				return checkLevels(gameConfigs?.powerBall);
			}
		} else {
			setModalAlert(false);
			setAwaitLoadingHTTP(false);
			setMessageModalIcon("warning");
			const message = t("Por-favor selecione o valor");
			setMessage(message);
			setOpenMessageModal(true);
			setIsLoading(false);
			return false;
		}
	};

	const checkout = async () => {
		if (router?.asPath === "/liveSports/") {
			setModalAlert(false);
			setOpenModalTimeLive(true);
		} else {
			await placeBet();
		}
	};

	useEffect(() => {
		setShowTimeModal(openModalTimeLive);
	}, [openModalTimeLive]);

	const getBetParticipant = (bet: any, market: any, game: any) => {
		const participants = game?.participants;

		if (market?.id === "2") {
			const betName = bet?.name;

			if (betName === "Over") {
				return participants[0];
			}
			if (betName === "Under") {
				return participants[1];
			}
		} else {
			const betName = bet?.name;
			const participant = participants[Number(betName) - 1];

			return participant;
		}
	};

	const placeBet = async () => {
		setAwaitLoadingHTTP(true);

		if (CART_BACKEND) {
			if (!isLoading) {
				setIsLoading(true);
				setModalAlert(false);
				setAwaitLoadingHTTP(true);

				if (checkConditions()) {
					let result = await setTotalBetCart(
						CART_BET_SETTING.merge({
							total: betValue,
							destination: { id: "sport", label: "", value: "sport" },
						}),
					);

					if (result.status) {
						if (betCart?.items && betCart?.items?.length > 0) {
							const referenceLevelTable = getReferenceLevelTable();
							result = await checkoutBetCart(
								ORDER_SETTING.merge({
									cart: {
										referencePath: betCart?.referencePath,
									},
									data: {
										validate: {
											referencePath: referenceLevelTable,
										},
									},
								}),
							);

							if (result.status) {
								setBetCart({});
								setModalAlert(false);
								setAwaitLoadingHTTP(false);
								setBetValue(0);
								betRef.current.value = 0;
								parseMessage(result?.data);
								setOpenAdditionalOptions(false);
								clearBetCart();

								if (close) {
									close(false);
								}

								setIsLoading(false);
								return;
							}
							if (
								!result?.status &&
								result?.data &&
								(result?.data?.error?.split(" ")[0] === "acl" ||
									result?.data?.error?.split(" ")[0] === "params")
							) {
								await logout();
								const result = await clearCart(CART_BET_SETTING);
								setBetCart(result?.data);
							} else {
								console.error(result);
								setModalAlert(false);
								setAwaitLoadingHTTP(false);
								setMessageModalIcon("warning");

								let message = t(result?.error);
								// console.warn('ERROR', result?.error)
								// console.warn('message', message)

								if (result.data?.values) {
									for (let i = 0; i < result.data.values.length; i++) {
										message = message.replace(`<${i}>`, result.data.values[i]);
									}

									console.warn(message);
								}

								setMessage(message);
								setOpenMessageModal(true);
								setIsLoading(false);
								return;
							}
						} else {
							setModalAlert(false);
							setAwaitLoadingHTTP(false);
							setMessageModalIcon("warning");
							const message = t(
								"Por-favor selecione ao menos um item p/ apostar",
							);
							setMessage(message);
							setOpenMessageModal(true);
							setIsLoading(false);
							return;
							// return toast.error(<b>{t("Por-favor selecione ao menos um item p/ apostar")}.</b>)
						}
					} else {
						setMessageModalIcon("warning");
						const message = result?.error;
						setMessage(message);
						// setOpenMessageModal(true);
					}
				} else {
					// setModalAlert(false);
					setAwaitLoadingHTTP(false);
					// setMessageModalIcon('warning');
					// const message = t("Por-favor selecione o valor");
					// setMessage(message);
					// setOpenMessageModal(true);
					setIsLoading(false);
					return;
					// return toast.error(<b>{t("Por-favor selecione o valor.")}</b>)
				}
			}
		} else {
			const _result = await clearCart(CART_BET_SETTING);

			if (!isLoading) {
				setIsLoading(true);
				setModalAlert(false);
				setAwaitLoadingHTTP(true);

				if (checkConditions()) {
					let referencePath: any;

					for (let i = 0; i < betCart.items.length; i++) {
						let participant: any;

						if (!betCart.items[i].minigame) {
							participant = getBetParticipant(
								betCart.items[i].bet,
								betCart.items[i].market,
								betCart.items[i].game,
							);
						}

						let setting: any;

						if (!betCart.items[i].minigame) {
							setting = {
								league: {
									referencePath: betCart.items[i].game?.league?.referencePath,
								},
								market: {
									referencePath: betCart.items[i].market?.referencePath,
								},
								...(!betCart.items[i].minigame && {
									game: {
										referencePath: betCart.items[i].game?.referencePath.replace(
											"/documents/",
											"/flatFixture/",
										),
									},
								}),
								...(dividends && {
									dividend: { referencePath: dividends.referencePath },
								}),
								bet: { referencePath: betCart.items[i].bet.referencePath },
								data: betCart.items[i].bet,
							};
						} else {
							setting = {
								document: {
									referencePath: betCart.items[i].document.referencePath,
								},
								data: { bet: betCart.items[i].bet },
								gameSettings: {
									referencePath: betCart.items[i].gameSetting,
								},
							};
						}

						if (participant) {
							setting.data.participant = {
								referencePath: participant?.referencePath,
							};
						}

						setIsLoadingCart(true);

						let result: any;

						if (!betCart.items[i].minigame) {
							result = await setItemCart(CART_BET_SETTING.merge(setting));
						} else {
							result = await setMinigameItemCart(
								CART_BET_SETTING.merge(setting),
							);
						}

						if (result.status) {
							referencePath = result.data.referencePath;
						}

						setIsLoadingCart(false);
					}

					let result = await setTotalBetCart(
						CART_BET_SETTING.merge({
							total: betValue,
							destination: { id: "sport", label: "", value: "sport" },
						}),
					);

					if (result.status) {
						if (betCart?.items && betCart?.items?.length > 0) {
							const referenceLevelTable = getReferenceLevelTable();
							result = await checkoutBetCart(
								ORDER_SETTING.merge({
									cart: {
										referencePath: referencePath,
									},
									data: {
										validate: {
											referencePath: referenceLevelTable,
										},
									},
								}),
							);

							if (result.status) {
								//setBetCart({});
								setModalAlert(false);
								// setAwaitLoadingHTTP(false);
								setBetValue(0);

								if (betRef.current) {
									betRef.current.value = 0;
								}

								await parseMessage(result?.data);
								setOpenAdditionalOptions(false);
								await clearBetCart();

								if (close) {
									close(false);
								}

								setAwaitLoadingHTTP(false);
								setIsLoading(false);
								return;
							}
							if (
								!result?.status &&
								result?.data &&
								(result?.data?.error?.split(" ")[0] === "acl" ||
									result?.data?.error?.split(" ")[0] === "params")
							) {
								await logout();
								const result = await clearCart(CART_BET_SETTING);
								setBetCart(result?.data);
							} else {
								console.error(result);
								setModalAlert(false);
								setAwaitLoadingHTTP(false);
								setMessageModalIcon("warning");

								let message = t(result?.error);

								if (result.data?.values) {
									for (let i = 0; i < result.data.values.length; i++) {
										message = message.replace(`<${i}>`, result.data.values[i]);
									}

									console.warn(message);
								}

								setMessage(message);
								setOpenMessageModal(true);
								setIsLoading(false);
								return;
							}
						} else {
							setModalAlert(false);
							setAwaitLoadingHTTP(false);
							setMessageModalIcon("warning");
							const message = t(
								"Por-favor selecione ao menos um item p/ apostar",
							);
							setMessage(message);
							setOpenMessageModal(true);
							setIsLoading(false);
							return;
						}
					} else {
						setAwaitLoadingHTTP(false);
						setIsLoading(false);
						return;
					}
				}
			}
		}
	};

	const handleValueButton = async (value: any) => {
		if (!user) {
			setModalAlert(false);
			setAwaitLoadingHTTP(false);
			setMessageModalIcon("warning");
			const message = "이 기능을 사용하려면 로그인하세요!";
			setMessage(message);
			setOpenMessageModal(true);
			return;
		}

		if (user.sport <= 0) {
			setModalAlert(false);
			setAwaitLoadingHTTP(false);
			setMessageModalIcon("warning");
			const message = t("Saldo insuficiente");
			setMessage(message);
			setOpenMessageModal(true);
			return;
		}

		if (CART_BACKEND) {
			setSlipeisUpdating(true);

			if (timeoutId) {
				clearTimeout(timeoutId);
			}

			let sendValue: any;
			value = value ? value : 0;
			const userSportValue = user?.sport ? user.sport : 0;

			if (betValue + value > userSportValue) {
				setBetValue(userSportValue);
				betRef.current.value = format(userSportValue);
				sendValue = Number(userSportValue);
			} else {
				setBetValue(Number.parseInt(betValue) + value);
				betRef.current.value = format(Number.parseInt(betValue) + value);
				sendValue = Number(Number.parseInt(betValue) + value);
			}

			handleTimer(sendValue);
			// const newTimeoutId = setTimeout(async () => {
			//   const result = await setTotalBetCart(
			//     CART_BET_SETTING.merge({ total: sendValue, destination: { id: 'sport', label: '', value: 'sport' } })
			//   )

			//   if (result?.status) {
			//     setBetCart(result?.data)
			//     setSlipeisUpdating(false)
			//   } else {
			//     setBetValue(0)
			//     if (betRef && betRef.current) {
			//       betRef.current.value = 0
			//     }
			//     setMessageModalIcon('warning')
			//     const message = t(result.error)
			//     setMessage(message)
			//     setOpenMessageModal(true)
			//     setSlipeisUpdating(false)
			//   }

			// }, 1000)

			// setTimeoutId(newTimeoutId)
		} else {
			let _sendValue: any;
			value = value ? value : 0;
			const userSportValue = user?.sport ? user.sport : 0;

			if (betValue + value > userSportValue) {
				setBetValue(userSportValue);
				betRef.current.value = format(userSportValue);
				_sendValue = Number(userSportValue);
			} else {
				setBetValue(Number.parseInt(betValue) + value);
				betRef.current.value = format(Number.parseInt(betValue) + value);
				_sendValue = Number(Number.parseInt(betValue) + value);
			}
		}
	};

	const handleClearInput = async () => {
		// setAwaitLoadingHTTP(true)
		if (CART_BACKEND) {
			setSlipeisUpdating(true);

			if (timeoutId) {
				clearTimeout(timeoutId);
			}

			setBetValue(0);

			if (betRef?.current) {
				betRef.current.value = 0;
			}

			handleTimer(0);
			// const newTimeoutId = setTimeout(async () => {
			//   const result = await setTotalBetCart(CART_BET_SETTING.merge({ total: 0, destination: { id: 'sport', label: '', value: 'sport' } }))

			//   if (result?.status) {
			//     handleTimer(0)
			//     setSlipeisUpdating(false)
			//   }
			// }, 1000)

			// // const result = await setTotalBetCart(CART_BET_SETTING.merge({ total: 0 }))

			// setTimeoutId(newTimeoutId)
			// setAwaitLoadingHTTP(false)
		} else {
			setBetValue(0);

			if (betRef?.current) {
				betRef.current.value = 0;
			}
		}
	};

	const checkCartChange = async (_e: any) => {
		// if (!isLoading) {
		//   setIsLoadingCart(true)
		//   setModalAlert(false)
		//   setAwaitLoadingHTTP(true)
		//   // console.log(betCart)
		//   let result = await reloadBetCartItems(CART_BET_SETTING)
		//   // console.log(result.data)
		//   setIsLoadingCart(false)
		//   setAwaitLoadingHTTP(false)
		// }
	};

	const dispatchAlertModal = async (message: any) => {
		const path = router.asPath?.split("/")[1];

		if (path !== "mini") {
			setModalAlert(true);
			setModalMessage(message);
		} else {
			await checkout();
		}
	};

	const handleSubmitBet = async (_e: any) => {
		if (submited) {
			return;
		}

		setSubmited(true);

		const items = betCart?.items;

		if (betValue === 0) {
			setModalAlert(false);
			setAwaitLoadingHTTP(false);
			setMessageModalIcon("warning");
			const message = t("Por-favor selecione o valor");
			setMessage(message);
			setOpenMessageModal(true);
			setIsLoading(false);
			setSubmited(false);
			return;
		}

		if (!items || items.length === 0) {
			setModalAlert(false);
			setAwaitLoadingHTTP(false);

			setMessageModalIcon("warning");
			const message = t("Selecione uma opção de aposta");
			setMessage(message);
			setOpenMessageModal(true);
			setIsLoading(false);
			// setMessageModalIcon('warning');
			// setModalAlert(true);
			// setModalMessage("Selecione uma opção de aposta");
			setSubmited(false);
			return;
		}

		if (items?.length && items?.length <= 2) {
			let validated = true;

			if (items.length === 1) {
				if (items[0].price < 1.3) {
					setModalAlert(false);
					setMessageModalIcon("error");
					setMessage(
						t(
							"São possíveis apostas de pasta única com probabilidades de 1.3 ou mais.",
						),
					);
					setOpenMessageModal(true);
					validated = false;
					setIsLoading(false);
					setSubmited(false);
					return;
				}
			}

			items.forEach((e: any) => {
				if (e.price < 1.3) {
					dispatchAlertModal(
						"Se você selecionar e não apostar 2 jogos (2 ou mais pastas) com probabilidades de 1,3 vai ser aplicada rodada de Single",
					);
					// setModalAlert(true)
					// setModalMessage(
					//   'Se você selecionar e não apostar 2 jogos (2 ou mais pastas) com probabilidades de 1,3 vai ser aplicada rodada de Single'
					// )
					validated = false;
				}
			});

			if (validated) {
				dispatchAlertModal("Gostaria de fazer uma aposta?");
				// setModalAlert(true)
				// setModalMessage('Gostaria de fazer uma aposta?')
			}

			setSubmited(false);
			return;
		}

		if (items?.length && items?.length >= 3) {
			dispatchAlertModal("Gostaria de fazer uma aposta?");
			// setModalAlert(true)
			// setModalMessage('Gostaria de fazer uma aposta?')
			setSubmited(false);
			return;
		}
	};

	const handleBannerClick = (link: any) => {
		if (link) {
			window.open(link);
		}
	};

	// console.error('ccc', betValue, betRef.current.value);
	if (!isDesktop && openModalBetslip === true) {
		return (
			<div className={styles.betSlipMobile}>
				<div className={styles.container}>
					{/* <div className={styles.iconClose}>
                        <AiOutlineClose onClick={() => close(false)} />
                    </div> */}
					<HeaderForMobileMenu closeFunction={close} />

					<div className={styles.content}>
						{/* {user &&
                            <div className={styles.informationUser}>
                                <div className={styles.top}>
                                    <div className={styles.level}>
                                        <img src="/assets/img/image_level.png" alt="" />
                                        <p>{user?.level?.label}</p>
                                    </div>

                                    <div className={styles.user}>
                                        <div className={styles.information}>
                                            <span>{t("ID")}</span>
                                            <p className={styles.id}>{user?.code}</p>
                                        </div>

                                        <div className={styles.information}>
                                            <span>{t("Nickname")}</span>
                                            <p className={styles.days}>{user?.nickname}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.divisor} />

                                <div className={styles.informationsMonney}>
                                    <div className={styles.inputInformation}>
                                        <div className={styles.inputItem}>
                                            <img src="/assets/img/sportscash.png" alt="" />
                                            <label>{t("Dinheiro Esporte")}</label>
                                        </div>
                                        <span className={styles.value}>{
                                            user?.sport ?
                                                new Intl.NumberFormat("en").format(user?.sport)
                                                : 0
                                        } {t("Won")}</span>
                                    </div>

                                    <div className={styles.inputInformation}>
                                        <div className={styles.inputItem}>
                                            <img src="/assets/img/casinocash.png" alt="" />
                                            <label>{t("Dinheiro do Cassino")}</label>
                                        </div>
                                        <span className={styles.value}>{
                                            user?.casino ?
                                                new Intl.NumberFormat("en").format(user?.casino)
                                            : 0
                                        } {t("Won")}</span>
                                    </div>
                                </div>

                                <div className={styles.buttons}>
                                    <button onClick={() => router.push("/deposit?tabIndex=0")}>
                                        <img src="/assets/img/deposito.png" alt="" />
                                        {t("DEPOSITO")}
                                    </button>

                                    <button onClick={() => router.push("/deposit?tabIndex=1")}>
                                        <img src="/assets/img/payout.png" alt="" />
                                        {t("SAQUE")}
                                    </button>

                                    <button onClick={() => router.push("/deposit?tabIndex=5")}>
                                        <img src="/assets/img/note.png" alt="" />
                                        {t("Notificações")}
                                    </button>

                                    <button onClick={() => router.push("/deposit?tabIndex=4")}>
                                        <img src="/assets/img/customer.png" alt="" />
                                        {t("Perguntas")}
                                    </button>

                                    <button onClick={() => handleLogout()}>
                                        <img src="/assets/img/logout.png" alt="" />
                                        {t("Logout")}
                                    </button>
                                </div>
                            </div>
                        } */}

						<div className={styles.informationsMonney}>
							<div className={styles.inputInformation}>
								<div className={styles.inputItem}>
									<img src="/assets/img/sportscash.png" alt="" />
									<label>{t("Dinheiro Esporte")}</label>
								</div>
								<span className={styles.value}>
									{user?.sport
										? new Intl.NumberFormat("en").format(user?.sport)
										: 0}{" "}
									{t("Won")}
								</span>
							</div>

							<div className={styles.inputInformation}>
								<div className={styles.inputItem}>
									<img src="/assets/img/casinocash.png" alt="" />
									<label>{t("Dinheiro do Cassino")}</label>
								</div>
								<span className={styles.value}>
									{user?.casino
										? new Intl.NumberFormat("en").format(user?.casino)
										: 0}{" "}
									{t("Won")}
								</span>
							</div>
						</div>

						<div className={styles.fieldApostasy}>
							<div className={styles.top}>
								<h1>{t("Boletim de apostas")}</h1>
								<button
									onClick={() => {
										clearBetCart();
									}}
								>
									{t("Limpar tudo")}
								</button>
							</div>

							<p className={styles.text}>
								{betCart?.items?.length === 0 &&
									t("Não há apostas selecionadas.")}
								{betCart?.items?.map((item: any, key: any) => {
									return item.minigame ? (
										<BetMinigameData item={item} key={key} />
									) : (
										<BetItemData item={item} key={key} />
									);
								})}
							</p>

							<div
								style={
									betCart?.betBonus?.value
										? { display: "block" }
										: { display: "none" }
								}
							>
								<div
									className={
										betCart?.betBonus?.value
											? `${styles.bonusData}`
											: `${styles.bonusData} ${styles.hide}`
									}
								>
									<span className={styles.league}>&nbsp;</span>
									<div className={styles.buttonContainer}>
										<AiOutlineCloseCircle
											onClick={() => {
												clearBetBonus();
											}}
											className={styles.closeButton}
										/>
									</div>
								</div>
								<div className={styles.marketData}>
									<span className={styles.market}>
										{t(betCart?.betBonus?.label)}
									</span>
									<span className={styles.price}>
										{betCart?.betBonus?.value}
									</span>
								</div>
							</div>
						</div>

						{/* mobileAA */}
						<div className={styles.fieldsInput}>
							<div className={styles.top}>
								<h1>{t("Apostas")}</h1>
								<div className={styles.updateInput}>
									<SlReload
										onClick={() => {
											handleClearInput();
										}}
									/>
									<input
										type="text"
										defaultValue={betValue || 0}
										ref={betRef}
										onChange={handleChangeValue}
									/>
								</div>
							</div>

							<div className={styles.buttonValues}>
								<button
									className={styles.number}
									onClick={() => handleValueButton(5000)}
								>
									{t("5K")}
								</button>
								<button
									className={styles.number}
									onClick={() => handleValueButton(10000)}
								>
									{t("10K")}
								</button>
								<button
									className={styles.number}
									onClick={() => handleValueButton(50000)}
								>
									{t("50K")}
								</button>
								<button
									className={styles.number}
									onClick={() => handleValueButton(100000)}
								>
									{t("100K")}
								</button>
								<button
									className={styles.number}
									onClick={() => handleValueButton(1000000)}
								>
									{t("1KK")}
								</button>
								<button
									className={styles.number}
									onClick={() => handleValueButton(Number(user?.sport))}
								>
									{t("Max")}
								</button>
							</div>

							<div className={styles.datasValues}>
								<div className={styles.data}>
									<p className={styles.title}>{t("Dividendos totais")}:</p>
									<p className={styles.value}>
										{new Intl.NumberFormat("en", {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										}).format(betCart?.totalItems || 1)}{" "}
										{t("X")}
									</p>
								</div>

								<div className={styles.data}>
									<p className={styles.title}>
										{t("Valor total do dividendo")}:
									</p>
									<p className={styles.value}>
										{/* {new Intl.NumberFormat('en').format(
                      Math.round(betCart?.totalItems * betValue)
                    )}{' '}
                    {t('X')} */}
										{betCart?.totalItems
											? new Intl.NumberFormat("en").format(
													Math.round(betCart?.totalItems * betValue),
												)
											: 0}{" "}
										{t("Won")}
									</p>
								</div>

								<div className={styles.data}>
									<p className={styles.title}>{t("Valor Mínimo de Aposta")}:</p>
									<p className={styles.value}>
										{format(slipeInfo?.minimumBetAmount)} {t("Won")}
									</p>
								</div>

								<div className={styles.data}>
									<p className={styles.title}>{t("Valor máximo da aposta")}:</p>
									<p className={styles.value}>
										{format(slipeInfo?.maximumBetAmount)} {t("Won")}
									</p>
								</div>

								<div className={styles.data}>
									<p className={styles.title}>{t("Valor máximo de ganho")}:</p>
									<p className={styles.value}>
										{format(slipeInfo?.maximumWinningBetValue)} {t("Won")}
									</p>
								</div>

								<div className={styles.data}>
									<p className={styles.title}>
										{t("Dividendo Máximo de Apostas")}:
									</p>
									<p className={styles.value}>
										{slipeInfo?.maximumDividend} {t("X")}
									</p>
								</div>
							</div>
							{/* <button
                className={styles.buttonData}
                onClick={(e) => {
                  checkCartChange
                }}
              >
                {t('AAAAAAAAAAAAAA')}
              </button> */}
							<div className={styles.mobileSlipeBetButtons}>
								<button
									className={styles.buttonData}
									onClick={(e) => {
										// slipeisUpdating ? {} : handleSubmitBet(e);
										handleSubmitBet(e);
									}}
								>
									{slipeisUpdating ? (
										<Spinner size="xl" />
									) : (
										t("Fazer Uma Aposta")
									)}
								</button>
								<button
									className={`${styles.buttonData} ${styles.clear}`}
									onClick={(_e) => {
										clearBetCart();
									}}
								>
									{t("Limpar tudo")}
								</button>
							</div>
						</div>

						<div className={styles.imgContainer}>
							{homeBannersLiks?.map((banner: any) => (
								<ImageSetBet key={banner?.id} src={banner?.imageBanner?._url} />
							))}
						</div>
						{/* <h3>Betslip</h3>

                        <Tabs className={styles.tabsBetSlip}>
                            <TabList className={styles.titleTabsSlip}>
                                <Tab>Single</Tab>
                                <Tab>Multiple</Tab>
                                <Tab>System</Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    <BetslipItem />
                                </TabPanel>
                                
                                <TabPanel>
                                    <BetslipItem />
                                </TabPanel>
                                
                                <TabPanel>
                                    <BetslipItem />
                                </TabPanel>
                            </TabPanels>
                        </Tabs> */}
					</div>
				</div>
				{modalAlert && (
					<AlertModal
						open={modalAlert}
						onClose={setModalAlert}
						handler={checkout}
						message={modalMessage}
					/>
				)}
				{showTimeModal && (
					<ModalTimeLive
						liveLevels={gameConfigs}
						placeBet={placeBet}
						checkCartChange={checkCartChange}
					/>
				)}
			</div>
		);
	}

	return (
		<div className={styles.betSlip}>
			<div className={styles.content}>
				{user && (
					<div className={styles.informationUser}>
						<div className={styles.top}>
							<div className={styles.level}>
								<img src="/assets/img/image_level.png" alt="" />
								<p>{user?.level?.label}</p>
							</div>

							<div className={styles.user}>
								<div className={styles.information}>
									<span>ID</span>
									<p className={styles.id}>{user?.code}</p>
								</div>

								<div className={styles.information}>
									<span>{t("Nickname")}</span>
									<p className={styles.days}>{user?.nickname}</p>
								</div>
							</div>
						</div>

						<div className={styles.divisor} />

						<div className={styles.informationsMonney}>
							<div className={styles.inputInformation}>
								<div className={styles.inputItem}>
									<img src="/assets/img/sportscash.png" alt="" />
									<label>{t("Dinheiro Esporte")}</label>
								</div>
								<span className={styles.value}>
									{user?.sport
										? new Intl.NumberFormat("en", {
												maximumFractionDigits: 0,
											}).format(user?.sport)
										: 0}{" "}
									{t("Won")}
								</span>
							</div>

							<div className={styles.inputInformation}>
								<div className={styles.inputItem}>
									<img src="/assets/img/casinocash.png" alt="" />
									<label>{t("Dinheiro do Cassino")}</label>
								</div>
								<span className={styles.value}>
									{user?.casino
										? new Intl.NumberFormat("en", {
												maximumFractionDigits: 0,
											}).format(user?.casino)
										: 0}{" "}
									{t("Won")}
								</span>
							</div>
						</div>

						<div className={styles.buttons}>
							<button onClick={() => router.push("/deposit?tabIndex=0")}>
								<img src="/assets/img/deposito.png" alt="" />
								{t("Depósito")}
							</button>

							<button onClick={() => router.push("/deposit?tabIndex=1")}>
								<img src="/assets/img/payout.png" alt="" />
								{t("Saque")}
							</button>

							<button onClick={() => router.push("/deposit?tabIndex=4")}>
								<img src="/assets/img/note.png" alt="" />
								{t("Notificações")}
							</button>

							<button onClick={() => router.push("/deposit?tabIndex=3")}>
								<img src="/assets/img/customer.png" alt="" />
								{t("Perguntas")}
							</button>

							<button onClick={() => handleLogout()}>
								<img src="/assets/img/logout.png" alt="" />
								{t("Logout")}
							</button>
						</div>
					</div>
				)}

				<div className={styles.fieldApostasy}>
					<div className={styles.top}>
						<h1>{t("Boletim de apostas")}</h1>
						<button
							onClick={() => {
								clearBetCart();
							}}
						>
							{t("Limpar tudo")}
						</button>
					</div>

					<p className={styles.text}>
						{(betCart?.items?.length === 0 ||
							betCart?.items?.length === undefined) &&
							t("Não há apostas selecionadas.")}
						{betCart?.items?.map((item: any, key: any) => {
							return item.minigame ? (
								<BetMinigameData item={item} key={key} />
							) : (
								<BetItemData item={item} key={key} />
							);
						})}
					</p>
					<div
						style={
							betCart?.betBonus?.value
								? { display: "block" }
								: { display: "none" }
						}
					>
						<div
							className={
								betCart?.betBonus?.value
									? `${styles.bonusData}`
									: `${styles.bonusData} ${styles.hide}`
							}
						>
							<span className={styles.league}>&nbsp;</span>
							<div className={styles.buttonContainer}>
								<AiOutlineCloseCircle
									onClick={() => {
										clearBetBonus();
									}}
									className={styles.closeButton}
								/>
							</div>
						</div>
						<div className={styles.marketData}>
							<span className={styles.market}>
								{t(betCart?.betBonus?.label)}
							</span>
							<span className={styles.price}>{betCart?.betBonus?.value}</span>
						</div>
					</div>
				</div>

				<div className={styles.fieldsInput}>
					<div className={styles.top}>
						<h1>{t("Apostas")}</h1>

						<div className={styles.updateInput}>
							<SlReload onClick={() => handleClearInput()} />
							<input
								type="text"
								defaultValue={betValue}
								ref={betRef}
								onChange={handleChangeValue}
							/>
						</div>
					</div>

					<div className={styles.buttonValues}>
						<button
							className={styles.number}
							onClick={() => handleValueButton(5000)}
						>
							{t("5K")}
						</button>
						<button
							className={styles.number}
							onClick={() => handleValueButton(10000)}
						>
							{t("10K")}
						</button>
						<button
							className={styles.number}
							onClick={() => handleValueButton(50000)}
						>
							{t("50K")}
						</button>
						<button
							className={styles.number}
							onClick={() => handleValueButton(100000)}
						>
							{t("100K")}
						</button>
						<button
							className={styles.number}
							onClick={() => handleValueButton(1000000)}
						>
							{t("1KK")}
						</button>
						<button
							className={styles.number}
							onClick={() => handleValueButton(Number(user?.sport))}
						>
							{t("Max")}
						</button>
					</div>

					<div className={styles.datasValues}>
						<div className={styles.data}>
							<p className={styles.title}>{t("Dividendos totais")}</p>
							<p className={styles.value}>
								{new Intl.NumberFormat("en", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								}).format(betCart?.totalItems || 1)}{" "}
								{t("X")}
							</p>
						</div>

						<div className={styles.data}>
							<p className={styles.title}>{t("Valor total do dividendo")}</p>
							<p className={styles.value}>
								{betCart?.totalItems
									? new Intl.NumberFormat("en").format(
											Math.round(betCart?.totalItems * betValue),
										)
									: 0}{" "}
								{t("Won")}
							</p>
						</div>

						<div className={styles.data}>
							<p className={styles.title}>{t("Valor Mínimo de Aposta")}</p>
							<p className={styles.value}>
								{format(slipeInfo?.minimumBetAmount)} {t("Won")}
							</p>
						</div>

						<div className={styles.data}>
							<p className={styles.title}>{t("Valor máximo da aposta")}</p>
							<p className={styles.value}>
								{format(slipeInfo?.maximumBetAmount)} {t("Won")}
							</p>
						</div>

						<div className={styles.data}>
							<p className={styles.title}>{t("Valor máximo de ganho")}</p>
							<p className={styles.value}>
								{format(slipeInfo?.maximumWinningBetValue)} {t("Won")}
							</p>
						</div>

						<div className={styles.data}>
							<p className={styles.title}>{t("Dividendo Máximo de Apostas")}</p>
							<p className={styles.value}>
								{format(slipeInfo?.maximumDividend)} {t("X")}
							</p>
						</div>
					</div>
					{/* <button
            className={styles.buttonData}
            onClick={(e) => {
              checkCartChange(e)
            }}
          >
            {t('AAAAAAAAAAAAAA')}
          </button> */}
					<button
						className={styles.buttonData}
						onClick={(e) => {
							// slipeisUpdating ? {} : handleSubmitBet(e);<img
							handleSubmitBet(e);
						}}
					>
						{slipeisUpdating ? <CircularProgress /> : t("Fazer Uma Aposta")}
					</button>
				</div>

				<div className={styles.imagesLink}>
					{homeBannersLiks?.bannersLinks?.map((banner: any) => (
						<ImageSetBet
							alt=""
							key={banner?.id}
							className={styles.imageLink}
							src={banner?.imageBanner?._url}
							onClick={() => handleBannerClick(banner?.url)}
						/>
					))}
				</div>

				{/* <h3>Betslip</h3>
                <Tabs className={styles.tabsBetSlip}>
                    <TabList className={styles.titleTabsSlip}>
                        <Tab>Single</Tab>
                        <Tab>Multiple</Tab>
                        <Tab>System</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <BetslipItem />
                        </TabPanel>
                        
                        <TabPanel>
                            <BetslipItem />
                        </TabPanel>
                        
                        <TabPanel>
                            <BetslipItem />
                        </TabPanel>
                    </TabPanels>
                </Tabs> */}

				<div
					style={{
						color: "white",
						display: "flex",
						justifyContent: "end",
						alignItems: "center",
						fontSize: "9px",
					}}
				>
					<span> {version}</span>
				</div>
			</div>

			{modalAlert && (
				<AlertModal
					open={modalAlert}
					onClose={setModalAlert}
					handler={checkout}
					message={modalMessage}
				/>
			)}

			{showTimeModal && (
				<ModalTimeLive
					liveLevels={gameConfigs}
					placeBet={placeBet}
					checkCartChange={checkCartChange}
				/>
			)}
		</div>
	);
};
