import { collectionDocument } from "@/core-nextv3/document/document.api";
import { buildFilter } from "@/hooks/buildFilter";
import { LEAGUE_SETTING } from "@/setting/setting";
import { toZonedTime } from "date-fns-tz";
import { motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { GiLaurelsTrophy, GiWorld } from "react-icons/gi";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { HighlightGame } from "./components/HighlightGame";
import { HighlightLeague } from "./components/HighlightLeague";
import styles from "./styles.module.scss";

export const HighlightsWidget: React.FC<any> = ({ type }) => {
	const [toggle, setToggle] = useState(true);
	const [data, setData] = useState<any>();
	const { t } = useTranslation();

	useEffect(() => {
		fetch();
	}, []);

	const fetch = async () => {
		const queryParams: any = {};

		queryParams.startDate = toZonedTime(new Date(), "Asia/Seoul");
		queryParams.perPage = 5;
		queryParams.active = true;

		let result: any;

		if (type === "games") {
			queryParams.orderBy = "totalBets";

			result = await buildFilter(queryParams);
		}

		if (type === "leagues") {
			result = await collectionDocument(
				LEAGUE_SETTING.merge({
					where: [
						{
							field: "highlight",
							operator: "==",
							value: true,
						},
					],
				}),
			);
		}

		if (result.status && result.collection?.length > 0) {
			setData(result.collection);
			// if(type === 'leagues'){
			//    const leagues = [];
			//    for(let i = 0; i < 5; i++){
			//     leagues.push({
			//       sport: result.collection[i].sport,
			//       location: result.collection[i].location,
			//       league: result.collection[i].league})
			//     }
			//     setData(leagues)
			//   } else {
			//     const games = [];
			//         for(let i = 0; i < 5; i++){
			//           games.push({
			//             startDate: result.collection[i].startDate,
			//             sport: result.collection[i].sport,
			//             league: result.collection[i].league,
			//             participants:
			//             result.collection[i].participants})
			//           }
			//           setData(games)
			//         }
			//       }
		}
	};

	const handleToggle = () => {
		setToggle(!toggle);
	};

	return (
		<motion.section
			initial={true}
			className={styles.accordionHighlights}
			onClick={() => handleToggle()}
		>
			<motion.div className={styles.accordionTitle} layout>
				<div className={styles.nameIcon}>
					{type === "leagues" ? <GiWorld /> : <GiLaurelsTrophy />}
					<span>
						{type === "leagues"
							? t("Últimas ligas populares")
							: t("Últimos jogos populares")}
					</span>
				</div>
				{toggle ? (
					<TiArrowSortedDown className={styles.accordionSvg} />
				) : (
					<TiArrowSortedUp className={styles.accordionSvg} />
				)}
			</motion.div>
			<motion.div onClick={(e) => e.stopPropagation()}>
				{toggle
					? data?.map((item: any) =>
							type === "leagues" ? (
								<HighlightLeague key={item.id} data={item} />
							) : (
								<HighlightGame key={item.id} data={item} />
							),
						)
					: ""}
			</motion.div>
		</motion.section>
	);
};
