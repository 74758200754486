import { ImageSetBet } from "@/bet-nextv1/ImageSetBet";
import redirectTo from "@/bet-nextv1/utils/redirectTo";
import { useCore } from "@/core-nextv3/core/core";
import { useSubCore } from "@/hooks/useSubCore";
import { useRouter } from "next/router";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import styles from "./styles.module.scss";

export const HighlightLeague: React.FC<any> = ({ data }) => {
	const { setAwaitLoadingHTTP, selectedLanguage } = useCore();
	const router = useRouter();

	const { user } = useContext(AuthContext);

	const {
		setSelectedLeague,
		setSelectedLocation,
		setSelectedSport,
		setReloadGames,
		setVirtual,
		setEndDate,
		setOpenLoginModal,
	} = useSubCore();

	const handleSelectLeague = async () => {
		if (router?.asPath !== "/sports/") {
			setAwaitLoadingHTTP(true);
		}

		setSelectedSport(data.sport?.id ?? "esports");
		setVirtual(!data.sport?.id);

		setSelectedLeague(data.id.toString());

		setEndDate(null);
		setSelectedLocation(null);

		setReloadGames(true);

		if (router?.asPath !== "/sports/") {
			redirectTo("/sports", user, router, setOpenLoginModal);
		}

		setAwaitLoadingHTTP(false);
	};

	// const redirectTo = (path: any) => {
	//   console.warn("redirectTo", user, path)
	//   if (user) {
	//     router.push(path)
	//   }
	// }

	return (
		<div className={styles.content} onClick={() => handleSelectLeague()}>
			<div className={styles.sportIcon}>
				<ImageSetBet src={data?.sport?.icon?._url} alt="" />
			</div>
			<div className={styles.leagueIcon}>
				<ImageSetBet
					alt="flags"
					src={data?.flag?._url}
					placeholder={`/assets/img/flags/${data?.locationId}.png`}
				/>
			</div>
			<div>
				<span className={styles.leagueName}>
					{selectedLanguage === "pt" ? data?.name_br : data?.name_ko}
				</span>
			</div>
		</div>
	);
};
