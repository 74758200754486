import { useCore } from "@/core-nextv3/core/core";
import { useSubCore } from "@/hooks/useSubCore";
import { useEffect, useState } from "react";
import { TimeWrapper } from "./TimeWrapper";
import styles from "./styles.module.scss";

export const ModalTimeLive: React.FC<any> = ({
	liveLevels,
	placeBet,
	checkCartChange,
}) => {
	const { betCart } = useCore();
	const { openModalTimeLive } = useSubCore();

	const [timer, setTimer] = useState<any>(0);

	const getTime = (sport: any) => {
		switch (sport) {
			case "Football":
				return liveLevels?.timeSoccer;
			case "Basketball":
				return liveLevels?.timeBasketball;
			case "Baseball":
				return liveLevels?.timeBaseball;
			case "Volleyball":
				return liveLevels?.timeVolley;
			case "Virtual sports":
				return liveLevels?.timeEsport;
			case "Ice Hockey":
				return liveLevels?.timeIceHockey;
			default:
				return 10;
		}
	};

	const getLargeTimeCart = () => {
		const items = betCart?.items;
		let timeCounter = 0;

		for (let i = 0; i < items?.length; i++) {
			if (getTime(items[i]?.game?.sport?.name) > timeCounter) {
				timeCounter = getTime(items[i]?.game?.sport?.name);
			}
		}

		console.warn("TIME COUNTER", timeCounter);
		setTimer(timeCounter);
	};

	useEffect(() => {
		if (openModalTimeLive && betCart?.items) {
			getLargeTimeCart();
		}
	}, [openModalTimeLive]);

	return (
		<div className={styles.modalTimeLive}>
			<div className={styles.container} onClick={(e) => e.stopPropagation()}>
				<div className={styles.content}>
					<TimeWrapper
						timer={timer}
						referenceLevelTable={liveLevels?.referencePath}
						placeBet={placeBet}
						checkCartChange={checkCartChange}
					/>
				</div>
			</div>
		</div>
	);
};
